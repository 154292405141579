import Link from "next/link";
import { Box, Heading, Text, Anchor, Button } from "grommet";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import Layout from "@components/layout";
import e404Svg from "public/assets/images/404.svg";
import Image from "next/legacy/image";

export default function Page404() {
  const large = useIsLarge();

  return (
    <Layout seo={{ title: "404" }}>
      <Box
        direction={large ? "row" : "column"}
        align="center"
        justify="center"
        pad="large"
        gap="large"
      >
        <Image
          height={large ? "300px" : "150px"}
          width={large ? "300px" : "150px"}
          src={e404Svg}
          alt="404 Page Not Found"
        />
        <Box>
          <Heading level="2">
            <Text size="xlarge" color="dark-5" margin={{ right: "small" }}>
              404
            </Text>
            Page Not Found
          </Heading>
          <Box gap="small">
            <Text>Check if you have misspelled the link.</Text>
            <Text>
              This link should exists?{" "}
              <Anchor href="mailto:contact@coinscope.co">contact us</Anchor>
            </Text>
            <Link href="/" passHref legacyBehavior>
              <Button
                label="Continue Browsing Coins"
                margin={{ top: "medium" }}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
